<template>
  <div class="signUp container">
    <!-- 报名表 -->
    <div v-if="show=='form'&&!isEnd()" class="formBox">
      <h4 class="center formTitle">
        <span>龙腾之星报名表</span>
        <span v-if="info.id" class="textBtn" @click="showChange('works')">管理参赛作品</span>
      </h4>
      <Form ref="form" :model="info" :rules="rules" label-position="top" class="formStyle">
        <div class="formWrap">
          <div class="imgWrap">
            <FormItem prop="photo">
              <img v-if="info.photo" :src="getImageUrl(info.photo, 'competition_photo')" class="photoImg" alt="">
            </FormItem>  
            <div class="buttonText">
              <Upload
                :action="imgApi"
                :headers="{Authorization: token}"
                name="picture"
                :data="{applyType: 'COMPETITION'}"
                :show-upload-list="false"
                :on-success="handleSuccess"
                :before-upload="handleBeforeUpload" 
                :on-format-error="handleFormatError"
                :on-error="handleError"
                :on-exceeded-size="handleSize"
                :max-size="5120"
                accept="image/jpg, image/png, image/jpeg">
              <Icon v-if="!uploading" :type="info.photo?'ios-create-outline':'ios-add'" size="30" />
              <Icon v-if="uploading" type="ios-loading" size="40" class="icon-load" />
              <!-- <Button icon="md-add" ghost type="text" size="large" :loading="uploading" ></Button> -->
              <p>{{info.photo?'修改':'上传'}}本人照片</p>
              <p>（需正面免冠照）</p>
              </Upload>
            </div>
          </div>
          <Row :gutter="30">
            <!-- <Col :md="12" :xs="24">
              <FormItem prop="name" label="姓名">
                <Input type="text" v-model="info.name"></Input>
              </FormItem>
            </Col> -->
            <Col :md="12" :xs="24">
              <FormItem prop="university" label="所在院校/单位">
                <Input type="text" v-model="info.university"></Input>
              </FormItem>
            </Col>
            <Col :md="12" :xs="24">
              <FormItem prop="major" label="专业">
                <Input type="text" v-model="info.major"></Input>
              </FormItem>
            </Col>
            <Col :md="12" :xs="24">
              <FormItem prop="locId" label="高校地址" class="cityForm">
                <Select v-model="info.locId" placeholder="所在地" clearable @on-change="locIdChange">
                  <Option :value="p.id" v-for="p in locList" :key="p.id">{{p.name}}</Option>
                </Select>
              </FormItem>
              <FormItem prop="stateId" label=" " class="cityForm">
                <Select v-model="info.stateId" placeholder="省份/大洲" not-found-text="请先选择所在地" clearable @on-change="stateIdChange">
                  <Option :value="p.id" v-for="p in stateList" :key="p.id">{{p.name}}</Option>
                </Select>
              </FormItem>
              <FormItem prop="cityId" label=" " class="cityForm">
                <Select v-model="info.cityId" placeholder="城市/国家" not-found-text="请先选择省份/大洲" clearable>
                  <Option :value="p.id" v-for="p in cityList" :key="p.id">{{p.name}}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col :md="12" :xs="24">
              <FormItem prop="address" label="详细地址">
                <Input type="text" v-model="info.address"></Input>
              </FormItem>
            </Col>
            <Col :md="12" :xs="24">
              <FormItem prop="phone" label="手机">
                <Input type="text" v-model="info.phone"></Input>
              </FormItem>
            </Col>
            <Col :md="12" :xs="24">
              <FormItem prop="weChatId" label="微信号">
                <Input type="text" v-model="info.weChatId"></Input>
              </FormItem>
            </Col>
            <Col :md="12" :xs="24">
              <FormItem prop="email" label="电子邮箱">
                <Input type="text" v-model="info.email"></Input>
              </FormItem>
            </Col>
            <Col :md="12" :xs="24">
              <FormItem prop="sex" label="性别">
                <Select v-model="info.sex" placeholder="">
                  <Option value="男">男</Option>
                  <Option value="女">女</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="24">
              <FormItem prop="teacher1" label="指导老师（最多添加2位指导老师，一个输入框中只能输入一位老师姓名）">
                <Input type="text" v-model="info.teacher1" class="cityForm"></Input>
                <Input type="text" v-model="info.teacher2" class="cityForm"></Input>
                <!-- <Input type="text" v-model="info.teacher3" class="cityForm"></Input> -->
              </FormItem>
            </Col>
            <Col span="24">
              <FormItem prop="description" label="个人简介（1000字以内）">
                <Input type="textarea" :autosize="{minRows: 4}" v-model="info.description"></Input>
              </FormItem>
            </Col>
          </Row>
        </div>
      </Form>
      <div class="center">
        <Button class="signUpBtn" shape="circle" :loading="submitting" @click="submitForm">提交报名表</Button>
      </div>
    </div>
    <div v-if="show=='form'&&isEnd()" class="formBox">
      <h4 class="center formTitle">
        <span>本届报名已截止，请关注大赛后续活动</span>
      </h4>
    </div>
    <!-- 作品 -->
    <div v-if="show=='works'" class="workBox">
      <!-- <Row v-if="!isEnd()">
        <Col :lg="{span:16, push: 8}" :xs="24" align="right" class="btnLeft">
          <Button class="signUpBtn" shape="circle" @click="showChange('form')">管理报名信息</Button>
          <Button class="signUpBtn mgLeft" shape="circle" to="http://www.ddfddf.com/#/personal_info" target="_blank">完善个人档案</Button>
        </Col>
        <Col :lg="{span: 8, pull: 16}" :xs="24">
          <Button class="signUpBtn" shape="circle" @click="showType">提交参赛作品</Button>
        </Col>
      </Row> -->
      <div v-if="!isEnd()" class="center">
        <Button class="signUpBtn topBtn" @click="showType">提交参赛作品</Button>
        <Button class="signUpBtn topBtn" @click="showChange('form')">管理报名信息</Button>
        <Button class="signUpBtn topBtn" to="http://www.ddfddf.com/#/personal_info" target="_blank">完善个人档案</Button>
      </div>
      <div class="worksWrap">
        <h4>已提交作品（{{total}}）</h4>
        <div v-if="total==0" class="empty">
          <Icon class="infoIcon" type="ios-alert-outline"/>
          <span>还未提交作品</span>
        </div>
        <Row :gutter="30">
          <Col :lg="{span:8}" :xs="{span:12}" v-for="(l, i) in list" :key="l.id">
            <div class="workImgWrap">
              <img v-if="l.cover" :src="getImageUrl(l.cover, 'competition_works')" class="workImg" alt="">
            </div>
            <div class="center worksInfoWrap">
              <h4 class="ellipsis">{{l.name}}</h4>
              <p class="ellipsis">{{l.awardTypeName}}</p>
              <p v-if="!isEnd()">
                <span class="edit" @click="editWork(i)">编辑</span>
                <span class="delete" @click="deleteWork(l)"><Icon type="ios-trash-outline" size="16" />删除</span>
              </p>  
              <p v-if="isEnd()">获奖状态：<span class="awardTxt">{{getAwardsText(l.awards)}}</span></p>
            </div>
          </Col>
        </Row>
      </div>

      <!-- 选择作品参赛类型 -->
      <Modal v-model="workTypeShow"
        title="提交参赛作品"
        :mask-closable="false"
        :footer-hide="true"
        width="620">
        <div>
          <p class="smallTitle">选择作品参赛类别（单选）</p>
          <Row :gutter="30" v-for="(t, i) in awardTypeList" :key="i" >
            <Col :md="24" :xs="24" >
              <p class="typeFirst">{{t.name}}</p>
            </Col>
            <Col :md="12" :xs="24" v-for="(st, j) in t.subTypes" :key="j" >
              <div 
                class="typeItem" 
                :class="{'typeItemActive': workInfo.awardTypeName==st.name}" 
                @click="selectType(st)"
              >{{st.name}}</div>
            </Col>
          </Row>
          <p class="center"><Button class="signUpBtn" shape="circle" @click="nextStep">下一步</Button></p>
        </div>
      </Modal>
      <!-- 上传参赛作品 -->
      <Modal v-model="workInfoShow"
        title="编辑参赛作品"
        :mask-closable="false"
        :footer-hide="true"
        width="620">
        <WorkInfo v-if="workInfoShow" :info="workInfo" @EntrySucc="getWorkList" @PrevStep="prevStep" type="dragonStar" />
      </Modal>
    </div>
  </div>
</template>

<script>
import WorkInfo from "@/components/WorkInfo.vue";

import SignUpService from "@/api/signUp";
import { baseUrl } from "@/config/env";
export default {
  components: {
    WorkInfo
  },
  data() {
    return {
      show: null,
      imgApi: `${baseUrl}picture/upload`,
      token: this.$store.getters.token,
      userInfo: {},
      education: {},
      uploading: false,
      locList: [],
      stateList: [],
      cityList: [],

      info: {
        competitionId: "769358183172250",
        photo: "",
        university: "",
        major: "",
        teacher1: "",
        teacher2: '',
        teacher3: '',
        locId: null,
        stateId: null,
        cityId: null
      },
      rules: {
        photo: [
          {
            required: true,
            message: "请上传本人正面免冠照片",
            trigger: "change"
          }
        ],
        university: [{ required: true, message: "请输入所在院校/单位" }],
        major: [
          { required: true, message: "请输入专业", trigger: "blur,change" }
        ],
        locId: [
          { required: true, message: "请选择所在地", type: "number", trigger: "blur,change" }
        ],
        stateId: [
          { required: true, message: "请选择省份/大洲", type: "number", trigger: "blur,change" }
        ],
        cityId: [
          { required: true, message: "请选择城市/国家", type: "number", trigger: "blur,change" }
        ],
        address: [
          { required: true, message: "请输入通讯地址", trigger: "blur,change" }
        ],
        // postcode: [
        //   { required: true, message: "请输入邮编", trigger: "blur,change" }
        // ],
        phone: [
          { required: true, message: "请输入手机", trigger: "blur,change" }
        ],
        weChatId: [
          { required: true, message: "请输入微信号", trigger: "blur,change" }
        ],
        email: [
          {
            required: true,
            message: "请输入正确的电子邮箱",
            pattern: /[\w]+(\.[\w]+)*@[\w]+(\.[\w])+/,
            trigger: "blur,change"
          }
        ],
        sex: [
          { required: true, message: "请选择性别", trigger: "blur,change" }
        ],
        // teacher1: [
        //   { required: true, message: "请输入指导老师", trigger: "blur,change" }
        // ],
        description: [
          {
            required: true,
            message: "请输入个人简介（1000字以内）",
            trigger: "blur,change",
            max: 1000
          }
        ]
      },
      submitting: false,

      total: 0,
      list: [],
      page: 0,
      awardTypeList: [],

      workTypeShow: false,
      workInfoShow: false,

      workInfo: {},
      competitionId: "769358183172250",
      competitionInfo: {}
    };
  },
  mounted() {
    // 获取大赛信息，是否截止，显示页面
    this.getCompetition();
  },
  methods: {
    // 获取国家列表
    getLocList() {
      if (this.locList.length == 0) {
        this.getLocations("loc");
      }
    },
    // 获取省份列表
    getStateList() {
      if (this.info.locId==0 || this.info.locId == 1) {
        this.getLocations("state")
      }
    },
    // 获取城市列表
    getCityList() {
      if (this.info.stateId) {
        this.getLocations("city");
      }
    },
    // 获取位置列表
    getLocations(flag) {
      let promise;
      if (flag == "city") {
        promise = SignUpService.getLocations({
          "parentid.eq": this.info.stateId
        });
      } else if (flag == "state") {
        promise = SignUpService.getLocations({
          "parentid.eq": this.info.locId
        });
      } else {
        promise = SignUpService.getLocations();
      }
      promise
        .then(res => {
          if (res.errorNo == 0) {
            if (flag == "city") {
              this.cityList = res.data;
            } else if (flag == "state") {
              this.stateList = res.data;
            } else {
              this.locList = res.data;
            }
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          // this.$Message.error(err.message)
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 选择省
    locIdChange(){
      this.stateList = []
      this.info.stateId = null
      this.cityList = []
      this.info.cityId = null
      this.getStateList()
    },
    stateIdChange(){
      this.cityList = []
      this.info.cityId = null
      this.getCityList()
    },
    // 获取获奖状态文字
    getAwardsText(status) {
      switch (status) {
        case "SELECT":
          return "入围";
        case "GOOD":
          return "优秀";
        case "FINAL":
          return "提名";
        case "BRONZE":
          return "铜奖";
        case "SILVER":
          return "银奖";
        case "GOLD":
          return "金奖";
        case "ULTIMATE":
          return "全场大奖";
        default:
          return "未获奖";
      }
    },
    // 获取大赛信息
    getCompetition() {
      SignUpService.getOneCompetition(this.competitionId)
        .then(res => {
          if (res.errorNo == 0) {
            if (res.data) {
              this.competitionInfo = res.data;
              if (this.isEnd()) {
                this.$Modal.info({
                  title: "提示",
                  content: "报名已截止",
                  okText: "确定",
                  onOk: () => {
                    this.$router.push("/dragon_star/index");
                  }
                });
              } else {
                this.showInfo();
              }
            }
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          // this.$Message.error(err.message)
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 报名是否截止
    isEnd() {
      // let endDate = '2021-11-10'
      let endDate = this.competitionInfo.endDate;
      let dt = new Date();
      let sYear = dt.getFullYear() + "";
      let sMonth =
        dt.getMonth() + 1 < 10
          ? "0" + (dt.getMonth() + 1)
          : dt.getMonth() + 1 + "";
      let sDate = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate() + "";
      let dNow = sYear + "-" + sMonth + "-" + sDate;
      if (dNow > endDate) {
        return true;
      }
      return false;
    },
    // 判断显示哪个页面
    showInfo() {
      // 先判断是否提交过报名表，
      // 如果没有提交过报名表，url中有isShow参数先显示报名须知弹出层，
      // 如果没有isShow参数直接显示报名表，
      // 如果提交过报名表显示参赛作品页
      if (this.gIsLogin()) {
        this.userInfo = this.$store.getters.userInfo || {};
        this.getAwardType();
        this.getWorkList();
        this.getEducationList();
        this.getLocList()
        setTimeout(this.getInfo, 500);
        // setTimeout(this.getEducationList(), 1000)
      } else {
        this.$Modal.confirm({
          title: "提示",
          content: "登录后才能报名",
          okText: "去登录",
          onOk: () => {
            this.$router.push("/login?fromUrl=/dragon_star/sign_up");
          },
          onCancel: () => {
            this.$router.go(-1);
          }
        });
      }
    },
    // 提交报名表
    submitForm() {
      // 正式代码需要删除
      // this.show = 'works'
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitting = true;
          let teacher = this.info.teacher1
          if(this.info.teacher2){
            teacher += ' ' + this.info.teacher2
          }
          if(this.info.teacher3){
            teacher += ' ' + this.info.teacher3
          }
          this.info.teacher = teacher
          SignUpService.saveCompetitionRegistration(this.info)
            .then(res => {
              if (res.errorNo == 0) {
                // 提交成功之后执行
                // 显示作品信息
                this.show = "works";
              } else {
                this.$Message.error(res.errorMessage);
              }
              this.submitting = false;
            })
            .catch(() => {
              this.$Message.error("连接服务器失败，请稍后再试");
              this.submitting = false;
            });
        } else {
          this.$Message.error("信息未正确填写完整");
        }
      });
    },
    // 获取报名信息
    getInfo() {
      let param = { "competitionId.eq": this.competitionId };
      SignUpService.getCompetitionRegistration(param)
        .then(res => {
          if (res.errorNo == 0) {
            if (res.data) {
              if (res.data.length > 0) {
                let data = res.data[0]

                if(data.teacher){
                  let list = data.teacher.split(/ |,|，/)
                  data.teacher1 = list[0];
                  data.teacher2 = list[1];
                  data.teacher3 = list[2];
                }
                this.info = data;

                if(data.locId==0||data.locId==1){
                  this.getStateList()
                }
                if(data.stateId){
                  this.getCityList()
                }

                if (this.info.photo) {
                  this.show = "works";
                } else {
                  let isShow = this.$route.query.isShow;
                  if (isShow == 1) {
                    this.$parent.toSignUp();
                  }
                  this.show = "form";
                }
              } else {
                let isShow = this.$route.query.isShow;
                if (isShow == 1) {
                  this.$parent.toSignUp();
                }
                this.show = "form";

                if (this.userInfo.address) {
                  this.info.address = this.userInfo.address;
                }
                if (this.userInfo.wechatSubscription) {
                  this.info.weChatId = this.userInfo.wechatSubscription;
                }
                if (this.userInfo.email) {
                  this.info.email = this.userInfo.email;
                }
                if (this.userInfo.sex) {
                  this.info.sex = this.userInfo.sex;
                }
                if (this.userInfo.description) {
                  this.info.description = this.userInfo.description;
                }
                if(this.userInfo.mobilePhone){
                  this.info.phone = this.userInfo.mobilePhone
                }

                if (this.education) {
                  this.info.university = this.education.university;
                  this.info.major = this.education.major;
                  if (this.education.tutor) {
                    let list = this.education.tutor.split(/ |,|，/)
                    this.info.teacher1 = list[0];
                    this.info.teacher2 = list[1];
                    this.info.teacher3 = list[2];
                  }
                }
              }
            }
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 获取奖项类型
    getAwardType() {
      let param = { "competitionId.eq": this.competitionId };
      SignUpService.getAwardTypes(param)
        .then(res => {
          if (res.errorNo == 0) {
            if (res.data) {
              this.awardTypeList = res.data;
            }
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          // this.$Message.error(err.message)
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 获取教育背景
    getEducationList() {
      SignUpService.getEducationByAccountId({
        "accountId.eq": this.userInfo.accountId
      })
        .then(res => {
          if (res.errorNo == 0) {
            if (res.data) {
              if (res.data.length > 0) {
                this.education = res.data[0];
              }
            }
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 切换显示报名表和参赛作品
    showChange(val) {
      this.show = val;
    },
    // 提交参赛作品
    showType() {
      this.workInfo = { awardTypeId: "", awardTypeName: "", pictures: [] };
      this.workTypeShow = true;
    },
    // 编辑参赛作品
    editWork(i) {
      this.workInfo = Object.assign({}, this.list[i]);
      this.workTypeShow = true;
    },
    // 删除参赛作品
    deleteWork(work) {
      this.$Modal.confirm({
        title: "删除参赛作品",
        content: "确定要删除参赛作品“" + work.name + "”吗？",
        onOk: () => {
          // this.$Message.info('Clicked ok');
          SignUpService.deleteOneProject(work.id)
            .then(res => {
              if (res.errorNo == 0) {
                this.$Message.info("删除参赛作品成功");
                this.getWorkList();
              } else {
                this.$Message.error(res.errorMessage);
              }
            })
            .catch(() => {
              this.$Message.error("连接服务器失败，请稍后再试");
            });
        },
        onCancel: () => {
          // this.$Message.info('Clicked cancel');
        }
      });
    },
    // 提交作品成功后获取作品列表
    getWorkList() {
      this.workInfoShow = false;
      let param = {
        "userId.eq": this.userInfo.id,
        "competitionId.eq": this.competitionId
      };
      // let param = {'userId.eq':this.userInfo.id, 'competitionId.eq': this.competitionId, 'sort': 'id,DESC'}
      SignUpService.getProjects(param)
        .then(res => {
          if (res.errorNo == 0) {
            if (res.data) {
              this.total = res.data.length;
              this.list = res.data;
            }
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 上传照片成功
    handleSuccess(res) {
      if (res.errorNo) {
        this.$Message.error(res.errorMessage);
      } else {
        let data = res.data;
        this.info.photo = data.path;
        this.uploading = false;
        // this.$forceUpdate()
        this.$refs.form.validateField("photo");
      }
    },
    handleBeforeUpload(file) {
      let check = true;
      if (file.size == 0) {
        check = false;
      }
      this.uploading = true;
      return check;
    },
    // 文件超出最大值5M
    handleSize() {
      this.$Message.info("文件超过最大值5M，请重新选择！");
      this.uploading = false;
    },
    // 文件格式错误提示
    handleFormatError(file) {
      this.$Message.error(`文件${file.name}格式错误`);
      this.uploading = false;
    },
    // 错误提示
    handleError(error) {
      this.$Message.error("服务器错误" + error);
      this.uploading = false;
    },
    // 选择参赛作品类型
    selectType(type) {
      this.workInfo.awardTypeName = type.name;
      this.workInfo.awardTypeId = type.id;
      this.workInfo.competitionId = this.competitionId;
    },
    // 选择参赛类型后的下一步
    nextStep() {
      console.log(this.workInfo)
      if (this.workInfo.awardTypeId) {
       // if(!this.workInfo.id && (this.workInfo.awardTypeId==769359828934800 || this.workInfo.awardTypeId == 769359883800720)){
      //    let endDate = '2024-04-30'
       //   let dt = new Date();
        //  let sYear = dt.getFullYear() + "";
       //   let sMonth =
        //    dt.getMonth() + 1 < 10
        //      ? "0" + (dt.getMonth() + 1)
       //       : dt.getMonth() + 1 + "";
       //   let sDate = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate() + "";
       //   let dNow = sYear + "-" + sMonth + "-" + sDate;
        //  if (dNow > endDate) {
        //    this.$Message.error("主视觉形象设计类已截止提交，请选择其他类别");
        //    return;
        //  }
        //}
        this.workTypeShow = false;
        this.workInfoShow = true;
      } else {
        this.$Message.error("请选择作品参赛类别");
      }
    },
    // 上一步
    prevStep() {
      this.workInfoShow = false;
      this.workTypeShow = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.signUp {
  padding: 0 110px;

  .formBox {
    box-shadow: 0 2px 10px 3px rgba($color: #000000, $alpha: 0.1);
    padding: 20px 30px 30px;
    margin: 10px 0 50px;

    .formTitle {
      position: relative;
      margin-bottom: 20px;
      font-size: 16px;

      .textBtn {
        position: absolute;
        right: 0;
        top: 2px;
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
        color: #47b135;
      }
    }
    .formWrap {
      position: relative;
      padding-left: 150px;

      .cityForm{
        width: 33.33%;
        display: inline-block;
      }

      .imgWrap {
        position: absolute;
        width: 120px;
        left: 0;
        top: 15px;
        padding-bottom: 126px;
        background: #e6e6e6;

        .buttonText {
          // display: none;
          position: absolute;
          left: 0;
          right: 0;
          // top: 32px;
          top: 0;
          bottom: 0;
          padding: 30px 0;
          font-size: 12px;
          text-align: center;
          // color: #a2a2a2;
          color: #fff;
          background: rgba($color: #000000, $alpha: 0.35);
          cursor: pointer;
        }

        .icon-load {
          animation: m-loading 1s linear infinite;
          -webkit-animation: m-loading 1s linear infinite;
        }
        @keyframes m-loading {
          from {
            transform: rotate(0deg);
          }
          50% {
            transform: rotate(180deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        .photoImg {
          width: 100%;
          position: absolute;
        }
      }
      .imgWrap:hover .buttonText {
        display: inline-block;
      }
    }
  }
  .workBox {
    padding: 20px 30px 30px;

    .awardTxt {
      color: #47b135;
    }
    .mgLeft {
      margin-left: 15px;
    }
    .worksWrap {
      padding: 15px 0;

      .empty {
        text-align: center;
        padding: 50px 0;

        .infoIcon {
          font-size: 18px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }

      .workImgWrap {
        position: relative;
        padding-bottom: 65%;
        background: #a2a2a2;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 2px 5px 3px rgba($color: #47b135, $alpha: 0.1);
        .workImg {
          position: absolute;
          width: 100%;
        }
      }
      .worksInfoWrap {
        padding: 0 30px 10px;

        .edit {
          display: inline-block;
          cursor: pointer;
          padding-left: 20px;
          background: url(/img/editGreen.png) no-repeat left center;
          background-size: 12px;
          color: #47b135;
          font-size: 12px;
        }

        .delete {
          display: inline-block;
          cursor: pointer;
          margin-left: 10px;
          background-size: 12px;
          color: #47b135;
          font-size: 12px;
        }
      }
    }
  }
}

.signUpBtn {
  margin-top: 20px;
  padding: 0 50px;
}
.topBtn{
  // margin-right: 30px;
  // padding: 10px 50px;
  // height: auto;
  // line-height: 2;
  margin: 0 30px;
  padding: 0;
  width: 120px;
  height: 120px;
  line-height: 120px;
}
.smallTitle {
  font-size: 12px;
  padding-bottom: 10px;
}
.typeFirst {
  font-size: 14px;
  padding-bottom: 10px;
}
.typeItem {
  background: #e6e6e6;
  color: #221715;
  border-radius: 100px;
  margin-bottom: 18px;
  padding: 6px 0;
  text-align: center;
  cursor: pointer;
}
.typeItemActive {
  background: linear-gradient(to right, #0ba83b, #75bb2a);
  color: #ffffff;
  border-color: #75bb2a;
  box-shadow: 0 2px 5px 2px rgba($color: #47b135, $alpha: 0.3);
}

@media (max-width: 992px) {
  .signUp {
    padding-left: 0;
    padding-right: 0;

    .formBox,
    .workBox {
      padding-left: 15px;
      padding-right: 15px;
    }

    .formBox .formWrap {
      padding-left: 0;

      .imgWrap {
        position: relative;
        top: 0;
        margin: 0 auto 20px;
      }
    }
  }
  .signUpBtn {
    margin-top: 20px;
    padding: 0 35px;
  }
  .btnLeft {
    text-align: left;
  }
}
</style>
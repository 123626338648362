<template>
  <Form class="modalFormWrap formStyle" ref="form" :model="newInfo" label-position="left" :rules="rules">
    <Row :gutter="30">
      <Col span="24">
        <FormItem prop="name" label="作品名称（60字以内）">
          <Input type="text" v-model="newInfo.name"></Input>
        </FormItem>
      </Col>
      <!-- <Col span="12">
        <FormItem prop="designType" label="作品类型">
          <Select v-model="newInfo.designType" placeholder="">
            <Option v-for="t in designTypeList" :value="t.id.toString()" :key="t.id">{{t.name}}</Option>
          </Select>
        </FormItem>
      </Col> -->
      <Col span="12">
        <FormItem prop="completionDate" label="完成时间">
          <DatePicker type="date" :clearable="false" :editable="false" format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="newInfo.completionDate" @on-change="newInfo.completionDate=$event"></DatePicker>
        </FormItem>
      </Col>
      <Col span="12">
        <FormItem prop="price" label="项目金额（单位：万元）">
          <Input type="text" v-model="newInfo.price" :disabled="isPriceDisabled"></Input>
        </FormItem>
      </Col>
      <Col span="12">
        <FormItem prop="copyright" label="版权所有方">
          <Select v-model="newInfo.copyright" placeholder="" clearable>
            <Option value="SELF">本人</Option>
            <Option value="COMPANY">团队</Option>
            <Option value="PARTYA">学校</Option>
          </Select>
        </FormItem>
      </Col>
      <Col span="12">
        <FormItem prop="copyrightOwner" label="版权所有方名称">
          <Input type="text" v-model="newInfo.copyrightOwner"></Input>
        </FormItem>
      </Col>
      <Col span="24">
        <Row>
          <Col span="24" :class="isLocationRequired?'ivu-form-item-required':''">
            <span class="ivu-form-item-label">{{isLocationRequired?'项目实施地':'项目所在地'}}</span>
          </Col>
          <Col span="8">
            <FormItem prop="locId" :label-width="0" :rules="isLocationRequired?locIdRules:[]">
              <Select 
                v-model="newInfo.locId" 
                placeholder="所在地" 
                clearable
                @on-open-change="getLocList"
                @on-change="locSelect"
                :readonly="locReadonly"
                :disabled="locReadonly">
                <Option v-for="item in locList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="stateId" :label-width="0" :rules="isLocationRequired?stateIdRules:[]">
              <Select 
                v-model="newInfo.stateId" 
                placeholder="省份/大洲" 
                clearable
                @on-open-change="getStateList"
                @on-change="stateSelect"
                :readonly="locReadonly"
                :disabled="locReadonly">
                <Option v-for="item in stateList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="cityId" :label-width="0" :rules="isLocationRequired?cityIdRules:[]">
              <Select 
                v-model="newInfo.cityId" 
                placeholder="城市/国家" 
                clearable
                @on-open-change="getCityList"
                @on-change="citySelect"
                :readonly="locReadonly"
                :disabled="locReadonly">
                <Option v-for="item in cityList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </FormItem>
          </Col>  
        </Row>
      </Col>
      <!-- <Col span="12">
        <FormItem label="关联作品">
          <Input type="text" v-model="newInfo.relationProject"></Input>
        </FormItem>
      </Col>
      <Col span="12">
        <FormItem prop="teamType" label="设计形式">
          <Select v-model="newInfo.teamType" placeholder="">
            <Option value="SELF">个人</Option>
            <Option value="TEAM">团队</Option>
          </Select>
        </FormItem>
      </Col> -->
      <Col span="24">
        <FormItem label="主创人员（用空格隔开，主创加参与人员不能超过3人）" prop="author">
          <!-- <Checkbox v-model="newInfo.authorSelf" :disabled="newInfo.participantSelf">本人</Checkbox> -->
          <Input type="text" v-model="newInfo.author"></Input>
        </FormItem>
      </Col>
      <Col span="24">
        <FormItem label="参与人员（用空格隔开，主创加参与人员不能超过3人）">
          <!-- <Checkbox v-model="newInfo.participantSelf" :disabled="newInfo.authorSelf">本人</Checkbox> -->
          <Input type="text" v-model="newInfo.participant"></Input>
        </FormItem>
      </Col>
      <Col :md="12" :xs="24">
        <FormItem label="专利号（非必填，填写并查验无误，即可解锁此条设计价值）">
          <Input type="text" v-model="newInfo.patentNumber" placeholder="例: 202010011420.0"></Input>
        </FormItem>
      </Col>
      <Col :md="12" :xs="24">
        <FormItem label="著作权证书号（非必填，填写并查验无误，即可解锁此条设计价值）">
          <Input type="text" v-model="newInfo.certificateNumber" placeholder="例: No.00000001"></Input>
        </FormItem>
      </Col>
      <Col :md="12" :xs="24" v-if="type=='dragonStar'||type=='competition'">
        <FormItem label="指导老师（用空格隔开，不能超过2名）">
          <Input type="text" v-model="newInfo.teacher"></Input>
        </FormItem>
      </Col>
      <Col span="24">
        <FormItem prop="description" label="设计说明（1000字以内）">
          <Input type="textarea" :autosize="{minRows: 4}" v-model="newInfo.description"></Input>
        </FormItem>
      </Col>
      <Col span="24">
        <span class="ivu-form-item-label">作品图片（图片仅限JPG格式，分辨率建议300dpi，每张图片大小不超过5MB，最多上传30张）</span>
      </Col>
      <Col span="24">
        <div>
          <!-- <Button class="defaultBtn size12" shape="circle">上传项目图片</Button> -->
          <Upload
            ref="upload"
            multiple
            :action="imgApi"
            :headers="{Authorization: token}"
            name="pictures"
            :data="{applyType: 'SCENE'}"
            :show-upload-list="false"
            :on-success="handleSuccess"
            :before-upload="handleBeforeUpload" 
            :on-format-error="handleFormatError"
            :on-error="handleError"
            :on-exceeded-size="handleSize"
            :max-size="5120"
            accept="image/jpg, image/png, image/jpeg">
            <Tooltip theme="light" max-width="200" placement="bottom-start">
              <Button class="defaultBtn size12" shape="circle">上传作品图片</Button>
              <div slot="content" class="size12">
                <p>仅限JPG格式，大小不超过5MB</p>
              </div>
            </Tooltip>
          </Upload>
        </div>
        <div>
          <div class="imgWrap imgLabel" v-for="(picture,i) in uploadList" :key="picture.id">
            <template v-if="picture.status === 'finished'">
              <img class="img" :src="getImageUrl(picture.path, 'avatar')" alt="">
              <div class="imgMask">
                <Icon type="ios-eye-outline" class="eye" @click="openImg(getImageUrl(picture.path))" />
                <Icon type="ios-close-circle-outline" class="delImg" @click="removeImg(i)" />
              </div>
              <div class="size12" v-if="false">
                <div>
                  <span class="lightColor">标签</span>
                  <span class="editLabel" @click="editLabel(picture.id)">编辑</span>  
                  <!-- <span class="editLabel" @click="editLabel(0, 0)">编辑</span>   -->
                </div>
                <div class="labelInput" v-if="isLabelEdit==picture.id">
                  <Input type="text" @on-enter="updateLabel" v-model="picture.labels"></Input>
                </div>
                <!-- <div class="labelInput" v-if="isLabelEdit=='0_0'"><Input type="text" @on-enter="updateLabel"></Input></div> -->
                <div class="ellipsis labelText">{{picture.labels}}</div>
              </div>
            </template>
            <template v-else>
                <Progress v-if="picture.showProgress" :percent="picture.percentage" hide-info></Progress>
            </template>

            <Modal v-model="showLargePic"
              title="查看图片" 
              :footer-hide="true"
              :styles="{top: '50px'}"
              width=640>
              <img :src="largePicPath" v-if="showLargePic" style="width: 100%">
            </Modal>
          </div>  
        </div>
        
      </Col>
      <Col span="24" v-if="type=='dragonStar'||type=='competition'" style="margin-top:24px">
        <span class="ivu-form-item-label">作品视频（视频仅限MP4格式，时长不超过{{videoMaxTime}}分钟，大小不超过{{videoMaxSize/1024}}MB）</span>
      </Col>
      <Col span="24" v-if="type=='dragonStar'||type=='competition'">
        <div>
          <Upload
            ref="videoBtn"
            :action="videoApi"
            :headers="{Authorization: token}"
            name="videos"
            :data="{applyType: 'VIDEO'}"
            :show-upload-list="false"
            :on-success="handleSuccessVideo"
            :before-upload="handleBeforeUploadVideo" 
            :on-format-error="handleFormatErrorVideo"
            :on-error="handleErrorVideo"
            :on-exceeded-size="handleSizeVideo"
            :on-progress="handleProgressVideo"
            :max-size="videoMaxSize"
            accept="video/mp4">
            <Tooltip theme="light" max-width="200" placement="bottom-start">
              <Button class="defaultBtn size12" shape="circle">上传作品视频</Button>
              <div slot="content" class="size12">
                <p>可上传格式：MP4，时长不超过{{videoMaxTime}}分钟，大小不超过{{videoMaxSize/1024}}MB。</p>
              </div>
            </Tooltip>
          </Upload>
        </div>
        <div class="videoWrap">
          <template v-if="newInfo.videos&&newInfo.videos.length>0">
            <video :src="getVideoUrlQCloud(newInfo.videos[0].path)" controls="controls" width="100%" :alt="newInfo.videos[0].name">
              您的浏览器不支持 video 标签。
            </video>
            <div class="imgMask">
              <!-- <Icon type="ios-eye-outline" class="eye" @click="openVideo(getVideoUrlQCloud(newInfo.videos[0].path))" /> -->
              <Icon type="ios-close-circle-outline" class="delImg" @click="removeVideo()"/>
            </div>
          </template>
          <template v-else>
            <Progress v-if="fileUpload.showProgress" :percent="fileUpload.percentage" hide-info></Progress>
          </template>
          <Modal v-model="showLargeVideo"
            title="查看视频" 
            :footer-hide="true"
            :styles="{top: '50px'}"
            width=640>
            <video :src="largeVideoPath" v-if="showLargeVideo"  controls="controls" width="100%">
              您的浏览器不支持 video 标签。
            </video>
          </Modal>
        </div>
      </Col>
      <Col span="24">
        <div>
          <Checkbox v-model="isChecked">
            <span>承诺：1、本人/团队承诺参赛作品为原创作品，若有知识产权纠纷或争议，其法律责任由本人自行负责。2、本人/团队承诺参赛作品一旦获奖，署名权外的知识产权归属于北京绿色设计促进会及相关主办方所有。</span>
          </Checkbox>
        </div>
      </Col>
    </Row>
    <div v-if="type=='dragonStar'" class="starBtn">
      <Button class="defaultBtn" shape="circle" @click="saveCompetitionWork">提交参赛作品</Button>
      <Button type="text" class="rightBtn" @click="prevStep">上一步</Button>
    </div>
    <div v-else class="btnWrap">
      <p class="textLeft pdBtm"><span class="addBtn" @click="addWork">添加其它代表作品</span></p>
      <Button class="defaultBtn formBtn" shape="circle" @click="saveWork">保存并跳转至下一未填项</Button>
      <Button type="text" class="rightBtn" @click="skip">跳过此页</Button>
    </div>
  </Form>
</template>

<script>
import SignUpService from "@/api/signUp";
import { baseUrl } from "@/config/env";
export default {
  props: ["info", "type"],
  data() {
    return {
      imgApi: `${baseUrl}pictures`,
      videoApi: `${baseUrl}picture/upload-video`,
      token: this.$store.getters.token,
      newInfo: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入作品名称（60字以内）",
            trigger: "blur,change",
            max: 60
          }
        ],
        // designType: [
        //   { required: true, message: "请选择作品类型", trigger: "blur,change" }
        // ],
        completionDate: [
          {
            required: true,
            message: "请选择完成时间",
            trigger: "blur,change",
            pattern: /.+/
          }
        ],
        price: [
          {
            required: false,
            message: "金额必须为数字且最多精确到小数点后两位",
            trigger: "blur,change",
            pattern: /^\d{1,10}(\.\d{1,2})?$/
          }
        ],
        copyright: [
          {
            required: true,
            message: "请选择版权所有方",
            trigger: "blur,change"
          }
        ],
        copyrightOwner: [
          {
            required: false,
            message: "版权所有方名称60字以内",
            trigger: "blur,change",
            max: 60
          }
        ],
        author: [
          {
            required: true,
            message: "请填写主创人员",
            trigger: "blur,change"
          }
        ],
        description: [
          {
            required: true,
            message: "请输入设计说明（1000字以内）",
            trigger: "blur",
            max: 1000
          }
        ]
      },
      locIdRules: [
        {
          required: true,
          message: "请选择所在地",
          trigger: "blur,change",
          type: "number"
        }
      ],
      stateIdRules: [
        {
          required: true,
          message: "请选择省份/大洲",
          trigger: "blur,change",
          type: "number"
        }
      ],
      cityIdRules: [
        {
          required: true,
          message: "请选择城市/国家",
          trigger: "blur,change",
          type: "number"
        }
      ],
      isLabelEdit: null,
      // designTypeList: [],
      // 国家
      locList: [],
      stateList: [],
      inStateList: [],
      outStateList: [],
      cityList: [],
      showLargePic: false,
      largePicPath: "",
      uploadList: [],

      fileUpload: { showProgress: false, percentage: 0 },
      showLargeVideo: false,
      largeVideoPath: "",
      videoMaxSize: 81920,
      videoMaxTime: 5,

      locReadonly: false,
      isChecked: true
    };
  },
  computed:{
    // 项目金额是否可修改（海报类和绿色产品类不能修改）
    isPriceDisabled(){
      let id = this.newInfo.awardTypeId
      if(id && (id==769360127305872 || id==769360147671184)){  //id==命题设计类id时返回false
        return false
      }
      return true
    },
    // 项目所在地是否必填（行唐美丽乡村景观环境规划设计类别必填，其他类别选填）
    isLocationRequired(){
      let id = this.newInfo.awardTypeId
      if(id && id==769360147671184){  //id==行唐美丽乡村景观环境规划设计id时返回true
        return true
      }
      return false
    }
  },
  mounted() {
    this.uploadList = this.$refs.upload.fileList;
    // console.log(this.uploadList)
    this.newInfo = Object.assign(
      {},
      {
        locId: null,
        stateId: null,
        cityId: null,
        participantSelf: false,
        authorSelf: false
      },
      this.info
    );
    if (!this.newInfo.pictures) {
      this.newInfo.pictures = [];
    } else {
      this.newInfo.pictures = Object.assign([], this.info.pictures);
      for (let i = 0; i < this.newInfo.pictures.length; i++) {
        this.newInfo.pictures[i].status = "finished";
        this.uploadList.push(this.newInfo.pictures[i]);
      }
    }
    if (!this.newInfo.videos) {
      this.newInfo.videos = [];
    }
    //昌都大赛作品所在地锁定昌都
    if (
      this.newInfo.competitionId &&
      this.newInfo.competitionId == "642883859953808"
    ) {
      this.newInfo.locId = 1;
      this.newInfo.stateId = 2730;
      this.newInfo.cityId = 2740;
      this.locReadonly = true;
      this.videoMaxSize = 10240;
      this.videoMaxTime = 1;
    }
    // else{
    //   this.newInfo.videos = Object.assign([], this.info.videos)
    // }
    // console.log(this.uploadList)
    this.newInfo.authorSelf = this.newInfo.authorSelf ? true : false;
    this.newInfo.participantSelf = this.newInfo.participantSelf ? true : false;
    // this.getDesignTypes();
    // this.getLocations();
    // this.getLocations("state");
    // this.getLocations("city");
    this.getLocList(true)
    this.newInfo.locId && this.getStateList(true)
    this.newInfo.stateId && this.getCityList(true)

    // console.log(this.newInfo)
  },
  methods: {
    // 龙腾之星提交参赛作品上一步
    prevStep() {
      this.$emit("PrevStep", {});
    },
    skip() {
      this.$emit("Skip", { key: "workInfo" });
    },
    // 查看大图
    openImg(path) {
      // window.open(path, '_blank')
      this.showLargePic = true;
      this.largePicPath = path;
    },
    // 删除图片
    removeImg(i) {
      this.newInfo.pictures.splice(i, 1);
      this.uploadList.splice(i, 1);
    },
    // 查看视频
    openVideo(path) {
      // window.open(path, '_blank')
      this.showLargeVideo = true;
      this.largeVideoPath = path;
    },
    // 删除视频
    removeVideo() {
      this.newInfo.videos.splice(0, 1);

      this.$forceUpdate();
    },
    // 保存参赛作品
    saveCompetitionWork() {
      if(!this.isChecked){
        this.$Message.warning("请勾选承诺内容");
        return;
      }
      // console.log(this.newInfo.pictures)
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.newInfo.pictures || this.newInfo.pictures.length == 0) {
            this.$Message.error("请上传作品图片");
            return;
          } else {
            for (let i = 0; i < this.newInfo.pictures.length; i++) {
              this.newInfo.pictures[i].status = null;
            }
          }
          if(this.newInfo.author){
            let authors = this.newInfo.author.split(' ');
            if(this.newInfo.participant){
              let participants = this.newInfo.participant.split(' ');
              if(authors.length+participants.length>3){
                this.$Message.error("小组成员不能超过3人");
                return;
              }
            }
            if(authors.length>3){
              this.$Message.error("小组成员不能超过3人");
              return;
            }
          }else{
            if(this.newInfo.participant){
              let participants = this.newInfo.participant.split(' ');
              if(participants.length>3){
                this.$Message.error("小组成员不能超过3人");
                return;
              }
            }
          }
          if(this.newInfo.teacher){
            let teachers = this.newInfo.teacher.split(' ');
            if(teachers.length>2){
              this.$Message.error("指导老师不能超过2名");
              return;
            }
          }


          if (typeof this.newInfo.completionDate != "string") {
            let dt = this.newInfo.completionDate;
            // console.log(dt)
            // dt.setHours(dt.getHours() + 8)
            let sYear = dt.getFullYear() + "";
            let sMonth =
              dt.getMonth() + 1 < 10
                ? "0" + (dt.getMonth() + 1)
                : dt.getMonth() + 1 + "";
            let sDate =
              dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate() + "";
            let dNew = sYear + "-" + sMonth + "-" + sDate;
            // this.newInfo.completionDate = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
            this.newInfo.completionDate = dNew;
          }
          if (!this.newInfo.competitionId) {
            this.newInfo.competitionId = "769358183172250";
          }
          SignUpService.saveCompetitionProject(this.newInfo)
            .then(res => {
              if (res.errorNo == 0) {
                this.$emit("EntrySucc");
                this.$Message.info("提交成功");
              } else {
                this.$Message.error(res.errorMessage);
              }
            })
            .catch(err => {
              // this.$Message.error(err.message)
              console.log(err.message);
              this.$Message.error("连接服务器失败，请稍后再试");
            });
        } else {
          this.$Message.error("信息未正确填写完整");
        }
      });
    },
    // 保存代表作品
    saveWork() {
      // console.log(this.newInfo)
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.newInfo.pictures || this.newInfo.pictures.length == 0) {
            this.$Message.error("请上传作品图片");
            return;
          } else {
            for (let i = 0; i < this.newInfo.pictures.length; i++) {
              this.newInfo.pictures[i].status = null;
            }
          }

          if (typeof this.newInfo.completionDate != "string") {
            let dt = this.newInfo.completionDate;
            // console.log(dt)
            // dt.setHours(dt.getHours() + 8)
            let sYear = dt.getFullYear() + "";
            let sMonth =
              dt.getMonth() + 1 < 10
                ? "0" + (dt.getMonth() + 1)
                : dt.getMonth() + 1 + "";
            let sDate =
              dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate() + "";
            let dNew = sYear + "-" + sMonth + "-" + sDate;
            // this.newInfo.completionDate = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
            this.newInfo.completionDate = dNew;
          }
          SignUpService.saveProject(this.newInfo)
            .then(res => {
              if (res.errorNo == 0) {
                this.$Message.info("保存成功");
                this.$parent.$parent.getWorkList();
                this.skip();
              } else {
                this.$Message.error(res.errorMessage);
              }
            })
            .catch(err => {
              // this.$Message.error(err.message)
              console.log(err.message);
              this.$Message.error("连接服务器失败，请稍后再试");
            });
        } else {
          this.$Message.error("信息未正确填写完整");
        }
      });
    },
    //
    addWork() {
      this.newInfo = {};
      this.uploadList = [];
    },
    // // 获取作品类型
    // getDesignTypes() {
    //   SignUpService.getDesignTypes({ "pid.ne": 0, size: 30 })
    //     .then(res => {
    //       if (res.errorNo == 0) {
    //         this.designTypeList = res.data;
    //       } else {
    //         this.$Message.error(res.errorMessage);
    //       }
    //     })
    //     .catch(() => {
    //       // this.$Message.error(err.message)
    //       this.$Message.error("连接服务器失败，请稍后再试");
    //     });
    // },
    // 选中国家
    locSelect() {
      this.stateList = [];
      this.newInfo.stateId = "";
      this.stateSelect();
      this.$forceUpdate();
    },
    // 选中省份
    stateSelect() {
      this.cityList = [];
      this.newInfo.cityId = "";
      this.$forceUpdate();
    },
    // 选中城市
    citySelect() {
      this.$forceUpdate();
    },
    // 获取国家列表
    getLocList(flag) {
      if (flag && this.locList.length == 0) {
        if(this.isLocationRequired){
          this.newInfo.locId = 1
          this.locList = [{id: 1, name: "中国", code: "1", parentid: -1}]
        }else{
          this.getLocations("loc");
        }
      }
    },
    // 获取省份列表
    getStateList(flag) {
      if (flag && (this.newInfo.locId==0 || this.newInfo.locId == 1)) {
        if (
          (this.newInfo.locId == 0 && this.outStateList.length == 0) ||
          (this.newInfo.locId == 1 && this.inStateList.length == 0)
        ) {
          // if(this.isLocationRequired){
          //   this.stateList = [
          //     {id: 1948, name: "广东", code: "44", parentid: 1},
          //     {id: 2093, name: "广西", code: "45", parentid: 1},
          //     {id: 2486, name: "贵州", code: "52", parentid: 1},
          //     {id: 2584, name: "云南", code: "53", parentid: 1},
          //     {id: 2217, name: "海南", code: "46", parentid: 1}
          //   ]
          // }else{
          //   this.getLocations("state");
          // }
          this.getLocations("state");
        } else {
          this.stateList =
            this.newInfo.locId == 0 ? this.outStateList : this.inStateList;
        }
      }
    },
    // 获取城市列表
    getCityList(flag) {
      if (flag && this.newInfo.stateId) {
        this.getLocations("city");
      }
    },
    // 获取位置列表
    getLocations(flag) {
      let promise;
      if (flag == "city") {
        promise = SignUpService.getLocations({
          "parentid.eq": this.newInfo.stateId
        });
      } else if (flag == "state") {
        promise = SignUpService.getLocations({
          "parentid.eq": this.newInfo.locId
        });
      } else {
        promise = SignUpService.getLocations();
      }
      promise
        .then(res => {
          if (res.errorNo == 0) {
            if (flag == "city") {
              this.cityList = res.data;
            } else if (flag == "state") {
              this.stateList = res.data;
              if (this.newInfo.locId == 0) {
                this.outStateList = res.data;
              }
              if (this.newInfo.locId == 1) {
                this.inStateList = res.data;
              }
            } else {
              this.locList = res.data;
            }
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          // this.$Message.error(err.message)
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 上传图片成功
    handleSuccess(res, file) {
      if (res.errorNo) {
        this.$Message.error(res.errorMessage);
      } else {
        let data = res.data;
        if (data.length > 0) {
          // data[0].status = 'finished'
          this.newInfo.pictures.push(data[0]);
          file.path = data[0].path;
          // this.uploadList.push(file)
        }
        // console.log(this.newInfo.pictures)
        this.$forceUpdate();
      }
    },
    handleBeforeUpload(file) {
      // let check = true
      let check = this.uploadList.length < 30;
      if (!check) {
        this.$Message.info("最多上传30张图片");
      }
      if (file.size == 0) {
        check = false;
      }
      // this.uploading = true
      return check;
    },
    // 文件超出最大值5M
    handleSize() {
      this.$Message.info("文件超过最大值5M，请重新选择！");
      // this.uploading = false
    },
    // 文件格式错误提示
    handleFormatError(file) {
      this.$Message.error(`文件${file.name}格式错误`);
      // this.uploading = false
    },
    // 错误提示
    handleError(error) {
      this.$Message.error("服务器错误" + error);
      // this.uploading = false
    },
    // 上传视频成功
    handleSuccessVideo(res) {
      this.fileUpload.showProgress = false;
      this.fileUpload.percentage = 0;
      if (res.errorNo) {
        this.$Message.error(res.errorMessage);
      } else {
        let data = res.data;
        if (data.length > 0) {
          this.newInfo.videos.push(data[0]);
        }
        this.$forceUpdate();
      }
    },
    handleBeforeUploadVideo(file) {
      let check = true;
      if (file.size == 0) {
        check = false;
      }
      this.fileUpload.showProgress = true;
      this.newInfo.videos = [];
      return check;
    },
    handleProgressVideo(event) {
      this.fileUpload.percentage = event.percent * 0.9;
    },
    // 文件超出最大值80M
    handleSizeVideo() {
      this.fileUpload.showProgress = false;
      this.$Message.info("文件超过最大值80M，请重新选择！");
    },
    // 文件格式错误提示
    handleFormatErrorVideo(file) {
      this.fileUpload.showProgress = false;
      this.$Message.error(`文件${file.name}格式错误`);
    },
    // 错误提示
    handleErrorVideo(error) {
      this.fileUpload.showProgress = false;
      // this.$Message.error('服务器错误' + error)
      console.log(error);
      this.$Message.error("服务器错误，请稍后再试");
    },
    // 图片标签编辑
    editLabel(picId) {
      let val = picId;
      this.isLabelEdit = val == this.isLabelEdit ? null : val;
    },
    // 提交图片标签
    updateLabel() {
      let key = this.isLabelEdit;
      console.log(key);
      // 提交成功后执行
      this.isLabelEdit = null;
    }
    // 图片标签编辑
    // editLabel(i, j){
    //   let val = `${i}_${j}`
    //   this.isLabelEdit = val==this.isLabelEdit ? null : val
    // },
    // 提交图片标签
    // updateLabel(){
    //   let key = this.isLabelEdit.split('_'), i = key[0], j = key[1]
    //   console.log(i, j)
    //   // 提交成功后执行
    //   this.isLabelEdit = null
    // }
  }
};
</script>
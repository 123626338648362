  /*
   * Copyright(c) 2021 eCert, All Rights Reserved.
   * Author: lxj
   */
  import fetch from '@/utils/fetch';

  export default {
    // 获取用户教育背景
    getEducationByAccountId: params => {
      params.sort = 'year,desc'
      return fetch({
        url: 'inquiry/educations',
        method: 'get',
        params
      })
    },
    // 获取相关专业
    getDesignTypes: params => {
      return fetch({
        url: 'inquiry/designtypes',
        method: 'get',
        params
      })
    },
    // 获取国家、省份、城市信息 参数parentid.eq
    getLocations: params => {
      if (params) {
        params.size = 239
      } else {
        params = {
          size: 239
        }
      }
      return fetch({
        url: 'inquiry/locations',
        method: 'get',
        params
      })
    },
    // 保存报名信息
    saveCompetitionRegistration: data => {
      return fetch({
        url: 'competition-registration/save',
        method: 'post',
        data
      })
    },
    // 获取报名信息
    getCompetitionRegistration: params => {
      return fetch({
        url: 'inquiry/competition-registration',
        method: 'get',
        params
      })
    },
    // 保存参赛作品信息
    saveCompetitionProject: data => {
      return fetch({
        url: 'competition-projects/save',
        method: 'post',
        data
      })
    },
    // 获取参赛作品列表
    getProjects: params => {
      return fetch({
        url: 'inquiry/competition-projects',
        method: 'get',
        params
      })
    },
    // 删除单个参赛作品
    deleteOneProject: id => {
      return fetch({
        url: `competition-projects/${id}`,
        method: 'delete'
      })
    },
    // 获取奖项类型
    getAwardTypes: params => {
      return fetch({
        url: 'inquiry/award-types',
        method: 'get',
        params
      })
    },
    // 获取大赛信息
    getOneCompetition: id => {
      return fetch({
        url: `inquiry/competitions/${id}`,
        method: 'get'
      })
    },
    // 保存作品信息
    saveProject: data => {
      return fetch({
        url: 'projects/save',
        method: 'post',
        data
      })
    },
  }